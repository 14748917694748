<template>
  <div
    id="access-line-charts"
    :style="{width: '100%', height: '360px',margin:'auto'}"
  />
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, watchEffect } from 'vue'

export default defineComponent({
  name: 'AccessLineCharts',
  props: ['data'],
  setup (props) {
    const { proxy }: any = getCurrentInstance()
    onMounted(() => {
      const accessLineCharts = proxy.$echarts.init(document.getElementById('access-line-charts'))
      const render = () => {
        accessLineCharts.setOption({
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['访问次数', 'IP 数', '访问人数']
          },
          toolbox: {
            show: true,
            bottom: 0,
            right: 20,
            feature: {
              dataView: { readOnly: false },
              magicType: { type: ['bar', 'line'] },
              saveAsImage: {}
            }
          },
          dataset: {
            dimensions: ['ip', 'pv', 'time', 'uv'],
            source: props.data
          },
          xAxis: {
            type: 'category',
            boundaryGap: false
          },
          yAxis: {},
          series: [
            {
              name: '访问次数',
              type: 'line',
              showSymbol: false,
              encode: {
                x: 'time',
                y: 'pv'
              }
            },
            {
              name: '访问人数',
              type: 'line',
              showSymbol: false,
              encode: {
                x: 'time',
                y: 'uv'
              }
            },
            {
              name: 'IP 数',
              type: 'line',
              showSymbol: false,
              encode: {
                x: 'time',
                y: 'ip'
              }
            }
          ]
        })
      }
      watchEffect(() => {
        render()
      })
      setTimeout(() => {
        accessLineCharts.resize()
        window.addEventListener('resize', () => accessLineCharts.resize())
      }, 200)
    })
  }
})
</script>
